<template>
  <c-box
    box-shadow="2px 2px 10px rgba(0, 0, 0, 0.15)"
    :p="['10px','16px']"
    v-bind="$attrs"
  >
    <c-flex justify-content="space-between">
      <c-flex flex-direction="column">
        <c-text
          font-family="Roboto"
          font-weight="400"
          :font-size="['14px', '16px']"
          color="#008C81"
        >
          {{ label }}
        </c-text>
        <c-text
          font-family="Roboto"
          font-weight="400"
          :font-size="['12px', '14px']"
        >
          {{ time }}
        </c-text>
      </c-flex>
      <c-box
        v-if="type == 'form'"
        as="label"
        d="flex"
        gap="8px"
        align-items="center"
      >
        <c-text
          font-family="Roboto"
          font-weight="400"
          :font-size="['14px', '16px']"
        >
          Tidak Makan
        </c-text>
        <c-checkbox
          variant-color="primary"
          :value="!isEat"
          :is-checked="!isEat"
          @change="handleChangeIsEat"
        />
      </c-box>
    </c-flex>

    <c-box
      v-if="type == 'form'"
      d="flex"
      align-items="center"
      overflow-x="auto"
      :height="['90px','140px']"
      mt="8px"
    >
      <c-box
        d="flex"
        gap="16px"
        align-items="center"
        margin="auto"
      >
        <c-box
          v-for="(photo, indexPhoto) in photos"
          :key="indexPhoto"
          :height="['60px','112px']"
          :width="['60px','112px']"
        >
          <c-box
            v-if="photo.blobPreview || !isDataEmpty(photo)"
            position="relative"
            d="inline-block"
          >
            <c-image
              :src="photo.blobPreview || photo"
              style="object-fit: cover;"
              :height="['60px','112px']"
              :width="['60px','112px']"
              rounded="8px"
            />
            <c-button
              v-chakra="{
                ':hover' : {
                  background: 'none'
                }
              }"
              p="0"
              m="0"
              background-color="transparent"
              :is-disabled="!hasPhoto || !isEat"
              position="absolute"
              top="-8px"
              right="-8px"
              :height="['20px','30px']"
              :width="['20px','30px']"
              @click="handleChangePhoto('delete-photo', indexPhoto, $event)"
            >
              <inline-svg
                :src="require('@/assets/icons/icon-circle-close.svg')"
                height="100%"
                width="100%"
                fill="red"
              />
            </c-button>
          </c-box>
          <c-button
            v-else
            :is-disabled="!hasPhoto || !isEat"
            p="0"
            h="100%"
            w="100%"
            as="label"
            d="inline-block"
            rounded="8px"
            overflow="hidden"
          >
            <inline-svg
              :src="require('@/assets/icons/icon-image-preview.svg')"
              height="100%"
              width="100%"
            />
            <c-input
              :is-disabled="!hasPhoto || !isEat"
              type="file"
              hidden
              @change="handleChangePhoto('input-photo', indexPhoto, $event)"
            />
          </c-button>
        </c-box>

        <c-button
          v-if="photos.length < 5"
          m="0"
          p="0"
          :is-disabled="!hasPhoto || !isEat"
          :height="['35px', '100px']"
          :width="['35px', '100px']"
          rounded="8px"
          overflow="hidden"
          @click="handleChangePhoto('add')"
        >
          <inline-svg
            :src="require('@/assets/icons/icon-image-add.svg')"
            height="100%"
            width="100%"
          />
        </c-button>
      </c-box>
    </c-box>

    <c-flex
      v-if="type == 'form'"
      my="20px"
      justify-content="center"
    >
      <c-box
        :opacity="!isEat ? '40%' : '100%'"
        as="label"
        d="flex"
        gap="8px"
        align-items="center"
      >
        <c-checkbox
          variant-color="primary"
          :value="!hasPhoto"
          :is-checked="!hasPhoto"
          :is-disabled="!isEat"
          @change="handleChangeHasPhoto"
        />
        <c-text
          font-family="Roboto"
          font-weight="400"
          :font-size="['14px', '16px']"
        >
          Tidak Ada Foto
        </c-text>
      </c-box>
    </c-flex>

    <c-box v-if="type != 'form'">
      <c-flex
        flex-dir="column"
        gap="10px"
        my="10px"
      >
        <c-flex
          v-if="isEat && (!hasPhoto || photos_.length == 0)"
          align-items="center"
          gap="8px"
          as="label"
        >
          <c-checkbox
            variant-color="primary"
            default-is-checked 
            is-disabled
          />
          <c-text
            font-family="Roboto"
            font-weight="400"
            :font-size="['14px', '16px']"
          >
            Tidak Ada Foto
          </c-text>
        </c-flex>
        <c-flex
          v-if="!isEat"
          align-items="center"
          gap="8px"
          as="label"
        >
          <c-checkbox
            variant-color="primary"
            default-is-checked 
            is-disabled
          />
          <c-text
            font-family="Roboto"
            font-weight="400"
            :font-size="['14px', '16px']"
          >
            Tidak Makan
          </c-text>
        </c-flex>
      </c-flex>

      <c-flex
        v-if="hasPhoto && photos_.length > 0"
        justify-content="center"
        gap="10px"
      >
        <c-box
          v-for="(photo, i) in photos_"
          :key="i"
          cursor="pointer"
          @click="handlePreviewPhoto(photo)"
        >
          <c-image
            width="120px"
            height="120px"
            border-radius="8px"
            :src="photo"
          />
        </c-box>
      </c-flex>
    </c-box>

    <FormInput
      v-if="!hasPhoto"
      v-model="descriptionFoodComputed"
      type="textarea"
      label="Makanan dan porsi"
      :placeholder="placeholder"
      is-without-margin-bottom
      :is-disabled="!isEat"
      is-required
      :is-invalid="!isDescriptionFoodValid"
      :is-read-only="type == 'form' ? false : true"
    />

    <c-modal
      :is-open="isOpenPreview"
      :on-close="handleClosePreview"
      is-centered
      size="xl"
    >
      <c-modal-content ref="content">
        <c-modal-header p="12px">
          Pratinjau Gambar
        </c-modal-header>
        <c-modal-close-button />
        <c-modal-body
          px="12px"
          pt="0"
          pb="1rem"
        >
          <c-image
            width="100%"
            height="auto"
            :src="preview"
          />
        </c-modal-body>
      </c-modal-content>
      <c-modal-overlay />
    </c-modal>
  </c-box>
</template>

<script>
import FormInput from '@/views/client/kuisioner/forms/form-input.vue'
import { isDataEmpty } from '@/utils/is-data-empty'

export default {
  name: 'FormFood',
  components: {
    FormInput,
  },
  props: {
    placeholderType: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      required: true,
    },
    time: {
      type: String,
      required: true,
    },
    photos: {
      type: Array,
      default: () => [],
    },
    hasPhoto: {
      type: Boolean,
      default: false,
    },
    isEat: {
      type: Boolean,
      default: true,
    },
    descriptionFood: {
      type: String,
      default: '',
    },
    isDescriptionFoodValid: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'form',
    },
  },
  data() {
    return {
      isOpenPreview: false,
      preview: null,
    }
  },
  computed: {
    placeholder() {
      switch (this.placeholderType) {
        case 'food':
          return 'Silahkan tulis apa saja yang kamu konsumsi beserta porsinya, contoh \nNasi putih 1 centong \nIkan goreng 1 potong \nTimun 5 potong kecil\ndsb'
        case 'snack':
          return 'Tulis tidak ada atau ketik strip \'-\' jika tidak ada konsumsi makanan pada bagian ini'
        default:
          return ' '
      }
    },
    descriptionFoodComputed: {
      get() {
        return this.descriptionFood
      },
      set(val) {
        if (this.type == 'form') {
          this.$emit('update:description-food', val)
        }
      },
    },
    photos_() {
      return this.photos.filter((el) => { return el && (typeof el == 'string') })
    },
  },
  methods: {
    isDataEmpty,
    handleChangeIsEat(_, e) {
      this.$emit('update:is-eat', !e.target.checked)
      if (e.target.checked) {
        this.$emit('update:has-photo', true)
      }
    },
    handleChangeHasPhoto(_, e) {
      if (this.type == 'form') {
        this.$emit('update:has-photo', !e.target.checked)
      }
    },
    handleChangePhoto(type, indexPhoto, e) {
      if (type === 'add') {
        const newPhotos = [...this.photos, {}]
        this.$emit('update:photos', newPhotos)
        return
      }

      if (type === 'input-photo') {
        const imageFile = e.target.files?.[0]
        const newPhotos = [...this.photos]
        newPhotos[indexPhoto] = {
          file: imageFile,
          blobPreview: URL.createObjectURL(imageFile),
        }
        this.$emit('update:photos', newPhotos)
        return
      }

      if (type === 'delete-photo') {
        const newPhotos = [...this.photos]
        newPhotos[indexPhoto] = {}
        this.$emit('update:photos', newPhotos)
        return
      }
    },
    handlePreviewPhoto(value) {
      this.preview = value
      this.isOpenPreview = true
    },
    handleClosePreview() {
      this.isOpenPreview = false
      this.preview = null
    },
  },
}
</script>
