var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', _vm._b({
    attrs: {
      "box-shadow": "2px 2px 10px rgba(0, 0, 0, 0.15)",
      "p": ['10px', '16px']
    }
  }, 'c-box', _vm.$attrs, false), [_c('c-flex', {
    attrs: {
      "justify-content": "space-between"
    }
  }, [_c('c-flex', {
    attrs: {
      "flex-direction": "column"
    }
  }, [_c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-weight": "400",
      "font-size": ['14px', '16px'],
      "color": "#008C81"
    }
  }, [_vm._v(" " + _vm._s(_vm.label) + " ")]), _c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-weight": "400",
      "font-size": ['12px', '14px']
    }
  }, [_vm._v(" " + _vm._s(_vm.time) + " ")])], 1), _vm.type == 'form' ? _c('c-box', {
    attrs: {
      "as": "label",
      "d": "flex",
      "gap": "8px",
      "align-items": "center"
    }
  }, [_c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-weight": "400",
      "font-size": ['14px', '16px']
    }
  }, [_vm._v(" Tidak Makan ")]), _c('c-checkbox', {
    attrs: {
      "variant-color": "primary",
      "value": !_vm.isEat,
      "is-checked": !_vm.isEat
    },
    on: {
      "change": _vm.handleChangeIsEat
    }
  })], 1) : _vm._e()], 1), _vm.type == 'form' ? _c('c-box', {
    attrs: {
      "d": "flex",
      "align-items": "center",
      "overflow-x": "auto",
      "height": ['90px', '140px'],
      "mt": "8px"
    }
  }, [_c('c-box', {
    attrs: {
      "d": "flex",
      "gap": "16px",
      "align-items": "center",
      "margin": "auto"
    }
  }, [_vm._l(_vm.photos, function (photo, indexPhoto) {
    return _c('c-box', {
      key: indexPhoto,
      attrs: {
        "height": ['60px', '112px'],
        "width": ['60px', '112px']
      }
    }, [photo.blobPreview || !_vm.isDataEmpty(photo) ? _c('c-box', {
      attrs: {
        "position": "relative",
        "d": "inline-block"
      }
    }, [_c('c-image', {
      staticStyle: {
        "object-fit": "cover"
      },
      attrs: {
        "src": photo.blobPreview || photo,
        "height": ['60px', '112px'],
        "width": ['60px', '112px'],
        "rounded": "8px"
      }
    }), _c('c-button', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra",
        value: {
          ':hover': {
            background: 'none'
          }
        },
        expression: "{\n              ':hover' : {\n                background: 'none'\n              }\n            }"
      }],
      attrs: {
        "p": "0",
        "m": "0",
        "background-color": "transparent",
        "is-disabled": !_vm.hasPhoto || !_vm.isEat,
        "position": "absolute",
        "top": "-8px",
        "right": "-8px",
        "height": ['20px', '30px'],
        "width": ['20px', '30px']
      },
      on: {
        "click": function click($event) {
          return _vm.handleChangePhoto('delete-photo', indexPhoto, $event);
        }
      }
    }, [_c('inline-svg', {
      attrs: {
        "src": require('@/assets/icons/icon-circle-close.svg'),
        "height": "100%",
        "width": "100%",
        "fill": "red"
      }
    })], 1)], 1) : _c('c-button', {
      attrs: {
        "is-disabled": !_vm.hasPhoto || !_vm.isEat,
        "p": "0",
        "h": "100%",
        "w": "100%",
        "as": "label",
        "d": "inline-block",
        "rounded": "8px",
        "overflow": "hidden"
      }
    }, [_c('inline-svg', {
      attrs: {
        "src": require('@/assets/icons/icon-image-preview.svg'),
        "height": "100%",
        "width": "100%"
      }
    }), _c('c-input', {
      attrs: {
        "is-disabled": !_vm.hasPhoto || !_vm.isEat,
        "type": "file",
        "hidden": ""
      },
      on: {
        "change": function change($event) {
          return _vm.handleChangePhoto('input-photo', indexPhoto, $event);
        }
      }
    })], 1)], 1);
  }), _vm.photos.length < 5 ? _c('c-button', {
    attrs: {
      "m": "0",
      "p": "0",
      "is-disabled": !_vm.hasPhoto || !_vm.isEat,
      "height": ['35px', '100px'],
      "width": ['35px', '100px'],
      "rounded": "8px",
      "overflow": "hidden"
    },
    on: {
      "click": function click($event) {
        return _vm.handleChangePhoto('add');
      }
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-image-add.svg'),
      "height": "100%",
      "width": "100%"
    }
  })], 1) : _vm._e()], 2)], 1) : _vm._e(), _vm.type == 'form' ? _c('c-flex', {
    attrs: {
      "my": "20px",
      "justify-content": "center"
    }
  }, [_c('c-box', {
    attrs: {
      "opacity": !_vm.isEat ? '40%' : '100%',
      "as": "label",
      "d": "flex",
      "gap": "8px",
      "align-items": "center"
    }
  }, [_c('c-checkbox', {
    attrs: {
      "variant-color": "primary",
      "value": !_vm.hasPhoto,
      "is-checked": !_vm.hasPhoto,
      "is-disabled": !_vm.isEat
    },
    on: {
      "change": _vm.handleChangeHasPhoto
    }
  }), _c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-weight": "400",
      "font-size": ['14px', '16px']
    }
  }, [_vm._v(" Tidak Ada Foto ")])], 1)], 1) : _vm._e(), _vm.type != 'form' ? _c('c-box', [_c('c-flex', {
    attrs: {
      "flex-dir": "column",
      "gap": "10px",
      "my": "10px"
    }
  }, [_vm.isEat && (!_vm.hasPhoto || _vm.photos_.length == 0) ? _c('c-flex', {
    attrs: {
      "align-items": "center",
      "gap": "8px",
      "as": "label"
    }
  }, [_c('c-checkbox', {
    attrs: {
      "variant-color": "primary",
      "default-is-checked": "",
      "is-disabled": ""
    }
  }), _c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-weight": "400",
      "font-size": ['14px', '16px']
    }
  }, [_vm._v(" Tidak Ada Foto ")])], 1) : _vm._e(), !_vm.isEat ? _c('c-flex', {
    attrs: {
      "align-items": "center",
      "gap": "8px",
      "as": "label"
    }
  }, [_c('c-checkbox', {
    attrs: {
      "variant-color": "primary",
      "default-is-checked": "",
      "is-disabled": ""
    }
  }), _c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-weight": "400",
      "font-size": ['14px', '16px']
    }
  }, [_vm._v(" Tidak Makan ")])], 1) : _vm._e()], 1), _vm.hasPhoto && _vm.photos_.length > 0 ? _c('c-flex', {
    attrs: {
      "justify-content": "center",
      "gap": "10px"
    }
  }, _vm._l(_vm.photos_, function (photo, i) {
    return _c('c-box', {
      key: i,
      attrs: {
        "cursor": "pointer"
      },
      on: {
        "click": function click($event) {
          return _vm.handlePreviewPhoto(photo);
        }
      }
    }, [_c('c-image', {
      attrs: {
        "width": "120px",
        "height": "120px",
        "border-radius": "8px",
        "src": photo
      }
    })], 1);
  }), 1) : _vm._e()], 1) : _vm._e(), !_vm.hasPhoto ? _c('FormInput', {
    attrs: {
      "type": "textarea",
      "label": "Makanan dan porsi",
      "placeholder": _vm.placeholder,
      "is-without-margin-bottom": "",
      "is-disabled": !_vm.isEat,
      "is-required": "",
      "is-invalid": !_vm.isDescriptionFoodValid,
      "is-read-only": _vm.type == 'form' ? false : true
    },
    model: {
      value: _vm.descriptionFoodComputed,
      callback: function callback($$v) {
        _vm.descriptionFoodComputed = $$v;
      },
      expression: "descriptionFoodComputed"
    }
  }) : _vm._e(), _c('c-modal', {
    attrs: {
      "is-open": _vm.isOpenPreview,
      "on-close": _vm.handleClosePreview,
      "is-centered": "",
      "size": "xl"
    }
  }, [_c('c-modal-content', {
    ref: "content"
  }, [_c('c-modal-header', {
    attrs: {
      "p": "12px"
    }
  }, [_vm._v(" Pratinjau Gambar ")]), _c('c-modal-close-button'), _c('c-modal-body', {
    attrs: {
      "px": "12px",
      "pt": "0",
      "pb": "1rem"
    }
  }, [_c('c-image', {
    attrs: {
      "width": "100%",
      "height": "auto",
      "src": _vm.preview
    }
  })], 1)], 1), _c('c-modal-overlay')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }